import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import ModuleSubmenuNav from "../modules/module-submenu-nav";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

import { slugify } from "../helpers/helpers";

const TemplateIndustry = ({ data, location, pageContext }) => {
  const { contentfulTemplateIndustry: page } = data;

  let relatedInsights = null;
  if (data.relatedInsights) {
    relatedInsights = data.relatedInsights.nodes;
  }

  let relatedCaseStudies = null;
  if (data.relatedCaseStudies) {
    relatedCaseStudies = data.relatedCaseStudies.nodes;
  }

  let relatedTeardowns = null;
  if (data.relatedTeardowns) {
    relatedTeardowns = data.relatedTeardowns.nodes;
  }
  const relatedContentProps = {
    relatedInsights: relatedInsights,
    relatedCaseStudies: relatedCaseStudies,
    relatedTeardowns: relatedTeardowns,
  };

  // subSolutions for Carousel
  let subSolutions = {
    services: [],
    industries: [],
  };

  if (page.subSolutions.length > 0 || pageContext.relatedIndustries.length > 0) {
    if (page.subSolutions.length > 0) {
      // subSolutions can include listing pages or services, so we filter for services only
      subSolutions.services = [...page.subSolutions.filter(service => service.__typename === 'ContentfulTemplateService')];
    }
    if (pageContext.relatedIndustries.length > 0) {
      // related industries are siblings of the current Industry that are applied to a Solution
      subSolutions.industries = [...pageContext.relatedIndustries.filter(industry => industry.__typename === 'ContentfulTemplateIndustry')];
    }
  }

  const mainImage = page?.mainImage;
  const metaTitle = page.seoPageTitle ? page.seoPageTitle : page.pageTitle;
  const metaDesc = page.seoPageDescription
    ? page.seoPageDescription.seoPageDescription
    : null;
  const metaImage = page?.seoShareImage?.file?.url;
  const header = page.header ? moduleConductor(page.header, 0) : null;

  let submenuLinks = [];

  /* TODO: pull renderCarousels out into a module or component, like RelatedContentGroups or something,
    refactor so it isn't hardcoded to individual types */

  const renderCarousels = (subItems) => {
    const relatedServices = subItems.services;
    const relatedIndustries = subItems.industries;

    let carousels = [];

    if (relatedServices.length > 0) {
      const servicesUpdatedSlugs = relatedServices.map((service, i) => {
        let obj = { ...service, slug: `/services/${service.slug}` };
        return obj;
      });
      
      carousels.push({items: servicesUpdatedSlugs, title: 'Services'});
    }

    if (relatedIndustries.length > 0) {
      const industriesUpdatedSlugs = relatedIndustries.map((industry, i) => {
        let obj = { ...industry, slug: `../industries/${industry.slug}` };
        return obj;
      });

      carousels.push({items: industriesUpdatedSlugs, title: 'Industries'});
    }

    const carouselReturn = carousels.map((carousel, i) => {
      return (
        <div className="template-solution__related-services">
          <ModuleCarousel
            numDesktopSlides={3}
            cards={carousel.items}
            moduleHeadline={carousel.title}
            submenuTitle={carousel.title}
          />
        </div>
      );
    });
  
    return carouselReturn;
  }

  const modules = page.modules
    ? page.modules.map((module, i) => {
        if (module.submenuTitle) {
          submenuLinks.push(module.submenuTitle);
        }
        /*
          Removed conditional code to insert carousels before a CTA Banner;
          Squashed BUG: a CTA Banner didn't exist, so carousels didn't render
        */
        return moduleConductor(module, i, relatedContentProps);
      })
    : null;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  return (
    <Layout isSidebar="contact" location={location}>
      <SwiftType
        customTitle={metaTitle}
        customDesc={metaDesc}
        customFeatImg={metaImage || mainImage?.file?.url}
      />
      <SEO
        title={metaTitle}
        description={metaDesc}
        url={location.href}
        image={metaImage || mainImage?.file?.url}
      />
      {header}
      {submenuLinks.length > 0 ? (
        <ModuleSubmenuNav
          items={submenuLinks.length > 0 ? submenuLinks : null}
        />
      ) : null}
      {modules}
      {renderCarousels(subSolutions)}
    </Layout>
  );
};

export default TemplateIndustry;

//query($slug: String!, $solutionTitle: String!) {
export const pageQuery = graphql`
  query($slug: String!, $today: Date) {
    contentfulTemplateIndustry(slug: { eq: $slug }) {
      slug
      pageTitle
      doNotIndex
      seoPageTitle
      seoPageDescription {
        seoPageDescription
      }
      seoShareImage {
        ...ContentfulAssetFragment
      }
      header {
        ...ModuleHeaderSimpleFragment
        ...ModuleHeaderWithMediaFragment
      }
      modules {
        ...ModuleExpandingCardsFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleCtaBannerFragment
        ...ModuleRelatedContentFragment
        ...ModuleCarouselFragment
        ...ModuleInsightBannerFragment
        ...ModuleFeaturedBannerFragment
      }
      subSolutions {
        ... on Node {
          ... on ContentfulTemplateService {
            __typename
            contentful_id
            slug
            pageTitle
            previewBlurb {
              previewBlurb
            }
          }
        }
        ... on Node {
          ... on ContentfulTemplateListingPage {
            __typename
            contentful_id
            slug
            pageTitle
            pageDescription {
              pageDescription
            }
            addCtaBanner {
              contentful_id
            }
          }
        }
      }
    }
    relatedInsights: allContentfulTemplateInsight(
      filter: {
        relatedSolution: { slug: { eq: $slug } }
        insightTitle: { ne: null }
      }
      sort: { order: DESC, fields: publishDate }
      limit: 4
    ) {
      nodes {
        ...ContentfulInsightCardFragment
      }
    }
    relatedCaseStudies: allContentfulTemplateInsight(
      filter: {
        relatedSolution: { slug: { eq: $slug } }
        insightTitle: { ne: null }
        insightType: { insightType: { eq: "Case Study" } }
      }
      sort: { order: DESC, fields: publishDate }
      limit: 4
    ) {
      nodes {
        ...ContentfulInsightCardFragment
      }
    }
    relatedTeardowns: allContentfulTemplateTeardownReport(
      filter: { title: { ne: null }, publishDate: { lte: $today } }
      sort: { fields: publishDate, order: ASC }
    ) {
      nodes {
        __typename
        contentful_id
        title
        slug
        reportType
        listingDescription {
          listingDescription
        }
        price
        publishDate
        productImage {
          gatsbyImageData
          # fluid {
          #   srcSet
          # }
        }
      }
    }
  }
`;

// relatedInsightsBySolution: allContentfulTemplateInsight(
//   sort: { order: DESC, fields: publishDate }
//   filter: { relatedSolution: { pageTitle: { eq: $solutionTitle } } }
//   limit: 4
// ) {
//   nodes {
//     insightType {
//       insightType
//     }
//     publishDate
//   }
// }
