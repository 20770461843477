import React from "react";
import { Link, graphql } from "gatsby";
/* BEM */
import BEMHelper from "react-bem-helper";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import scrollTo from "gatsby-plugin-smoothscroll";

import { slugify } from "../helpers/helpers";

const sectionName = "module-submenu-nav";
const bem = new BEMHelper({
  name: sectionName,
});

const ModuleSubmenuNav = (props) => {
  const { items } = props;

  return (
    <div {...bem()}>
      <div {...bem("link-container")}>
        {items.map((link, i) => {
          return (
            <React.Fragment>
              <button
                key={i}
                {...bem("link")}
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(`#${slugify(link)}`);
                }}
              >
                {link}
              </button>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ModuleSubmenuNav;
